var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"flex-column",attrs:{"dense":""}},[(_vm.showHeader)?_c('v-col',{staticClass:"flex-shrink-1 flex-grow-0"},[_c('the-tools-bar',{scopedSlots:_vm._u([{key:"tools-right",fn:function(){return [_c('global-filter',{attrs:{"globalFilter":_vm.globalFilterValue},on:{"search":function($event){return _vm.searchGlobalFilter($event)},"updateGlobalFilterValue":function (e) { return (_vm.globalFilterValue = e); }}}),_c('handle-columns',{attrs:{"crudName":_vm.crudParams.crudName,"headers":_vm.crudParams.headers},on:{"updateHeadersSpecs":_vm.updateHeadersSpecs}}),(_vm.crudParams.canImport)?_c('import-items',{attrs:{"importText":_vm.importText,"crud-actions":_vm.crudActions}}):_vm._e(),(_vm.crudParams.canExport)?_c('export-excel-btn',{attrs:{"options":_vm.options,"exportText":_vm.exportText,"export-excel-items-action":_vm.crudActions.exportExcelItems}}):_vm._e(),(_vm.crudParams.canCreate)?_c('create-item',{attrs:{"create-text":_vm.createText,"crud-actions":_vm.crudActions},on:{"itemCreated":_vm.loadItems}}):_vm._e()]},proxy:true}],null,false,3142759192)})],1):_vm._e(),_vm._t("tableHeader"),(_vm.filterableHeaders.length > 0 && _vm.showFilters)?_c('v-col',{staticClass:"flex-shrink-1 flex-grow-0"},[_c('CrudFilterBar',{attrs:{"filterableHeaders":_vm.filterableHeaders,"criteria":_vm.options.criteria,"global-filter":_vm.globalFilterValue},on:{"updateCriteria":function($event){return _vm.updateCriteria($event)},"resetOptions":_vm.resetOptions}})],1):_vm._e(),_c('v-col',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.calculateHeight),expression:"calculateHeight"}],ref:"tableCol",staticClass:"pa-0",attrs:{"id":"tableCol"}},[_c('v-data-table',{staticClass:"pa-0",attrs:{"headers":_vm.visibleHeaders,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"height":_vm.tableHeight,"footer-props":{
        'items-per-page-options': [20, 50, 100, -1],
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Cantidad por página',
        'show-current-page': true,
      },"hide-default-footer":_vm.itemsSelected.length > 1 && _vm.showFooter,"multi-sort":"","show-select":_vm.enableSelection,"fixed-header":""},on:{"update:options":[function($event){_vm.options=$event},_vm.loadItemsWithOptions],"update:items-per-page":function($event){return _vm.setUrlQuery($event, 'ipp')},"update:page":function($event){return _vm.setUrlQuery($event, 'page')},"update:sort-by":function($event){return _vm.setUrlQuery($event, 'sortBy')},"update:sort-desc":function($event){return _vm.setUrlQuery($event, 'sortDesc')}},scopedSlots:_vm._u([{key:"item.training_courses_count",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.training_courses_count)+" "),_c('filter-btn',{attrs:{"route-name":"trainingCourses","criteria":[{ field: 'client', operator: 'e', value: item.name }]}})]}},{key:"item.centers_count",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.centers_count)+" "),_c('filter-btn',{attrs:{"route-name":"centers","criteria":[{ field: 'client', operator: 'e', value: item.name }]}})]}},{key:"item.centers_training_courses_count",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.centers_training_courses_count)+" "),_c('filter-btn',{attrs:{"route-name":"trainingCourses","criteria":[{ field: 'client', operator: 'e', value: item.client }]}})]}},{key:"item.clients_count",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.clients_count)+" "),_c('filter-btn',{attrs:{"route-name":"clients","criteria":[{ field: 'business_group', operator: 'e', value: item.name }]}})]}},{key:"item.regenerateCertificates",fn:function(ref){
      var item = ref.item;
return [_c('regenerate-certificates-btn',{attrs:{"element-id":item.id,"disabled":!item.actions.canRegenerate}})]}},{key:"item.downloadDocuments",fn:function(ref){
      var item = ref.item;
return [_c('download-file-btn',{attrs:{"endpoint-url":'documents/' + item.downloadDocuments + '/download',"small":"","outlined":""}})]}},{key:"item.downloadTrainingCourseDocuments",fn:function(ref){
      var item = ref.item;
return [_c('download-file-btn',{attrs:{"endpoint-url":("documents/" + (item.downloadTrainingCourseDocuments) + "/download-for-training-course/" + (_vm.$route.params.id)),"small":"","outlined":""}})]}},{key:"item.downloadStudentDocuments",fn:function(ref){
      var item = ref.item;
return [_c('download-file-btn',{attrs:{"endpoint-url":("documents/" + (item.downloadStudentDocuments) + "/download-for-student/" + (_vm.$route.params.id)),"small":"","outlined":""}})]}},{key:"item.downloadFiles",fn:function(ref){
      var item = ref.item;
return [_c('download-file-btn',{attrs:{"endpoint-url":'files/' + item.downloadFiles + '/download',"small":"","outlined":""}})]}},{key:"item.student_name_edit",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'studentsEdit', params: { id: item.student_id } }}},[_vm._v(" "+_vm._s(item.student_name_edit)+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('index-actions',{attrs:{"item":item,"crud-actions":_vm.crudActions},on:{"deleteItem":function($event){return _vm.deleteItems($event)}}})]}},{key:"no-data",fn:function(){return [(_vm.options.criteria.length || _vm.options.globalFilter || _vm.options.page !== 1)?_c('div',{staticClass:"mt-4 mt-md-8"},[_vm._v(" No hay datos con estos filtros, prueba con otros o"),_c('br'),_c('v-btn',{staticClass:"mt-2",attrs:{"outlined":""},on:{"click":_vm.resetOptions}},[_vm._v(" Elimina los filtros ")])],1):_c('div',{staticClass:"mt-4 mt-md-8"},[_vm._v("No hay datos para mostrar")])]},proxy:true},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}},(_vm.itemsSelected.length)?{key:"footer",fn:function(){return [_c('bulk-actions-panel',{attrs:{"itemsSelected":_vm.itemsSelected,"crud-actions":_vm.crudActions},on:{"deleteItems":function($event){return _vm.deleteItems($event)},"refreshItems":_vm.loadItems},scopedSlots:_vm._u([{key:"bulkActions",fn:function(ref){
      var itemsSelected = ref.itemsSelected;
return [_vm._t("bulkActions",null,{"itemsSelected":itemsSelected})]}}],null,true)})]},proxy:true}:null],null,true),model:{value:(_vm.itemsSelected),callback:function ($$v) {_vm.itemsSelected=$$v},expression:"itemsSelected"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }